<template>
  <header>
    <div>
      <div>
        <div class="black-layer"></div>
        <div class="stars-layer">
          <video playsinline autoplay muted loop>
            <source src="../video/space.mp4" type="video/mp4">
          </video>
        </div>
        <h1><a href="#">FUGO</a></h1>
        <h2>Augmented &<br>Virtual Reality<br>(AR/VR) Development</h2>
      </div>
    </div>
    <div>
      <div>
        <div id="typewritter">
          <p class="element"></p>
        </div>
        <div id="marker">
          <img src="../marker/marker.png" alt="Marker">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Typed from 'typed.js';

export default {
  mounted: function() {
    window.addEventListener("load", function(event) { 
      document.querySelector('.stars-layer video').play();
      document.querySelector('h1').setAttribute('class', 'animated flipInX');
    });

    var typed = new Typed(".element", {
      strings: ["Open fugo.digital on your phone^5000", "Allow the camera^5000", "Scan me &amp; enjoy ;) ^5000"],
      smartBackspace: true,
      typeSpeed: 50,
      backSpeed: 20,
      loop: true
    });
  }
}
</script>

<style lang="scss">
header {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 64.59396751740139vw 29.88399071925754vw;
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 31.87409551374819vw;
  overflow: hidden;
  padding-left: 4.895591647331787vw;
  padding-top: 4.802784222737819vw;
  position: relative;

  > div {
    &:first-of-type {
      .black-layer {
        background-color: black;
        -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 87% 100%);
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 87% 100%);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      .stars-layer {
        -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 87% 100%);
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 87% 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        video {
          display: block;
          height: 100%;
          width: 105%;
          object-fit: fill;
          position: absolute;
          right: -30%;
          left: unset;
          top: 0;
        }
      }

      h1 {
        visibility: hidden;

        &.animated {
          visibility: visible;
        }

        a {
          background-image: url("../img/logo.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          min-height: 7.8vw;
          margin-bottom: 6.426914153132251vw;
          max-height: 185px;
          max-width: 466px;
          overflow: hidden;
          text-indent: -9000px;
          min-width: 16.93735498839907vw;
        }
      }

      h2 {
        font-family: "Source Light";
        font-size: 2.4vw;
        line-height: 1.35em;
        letter-spacing: 0.2em;
        text-align: left;
        text-transform: uppercase;
        padding: 0;
      }
    }

    &:last-of-type {
      position: absolute;
      right: 5.716353111432706vw;
      top: 2.858176555716353vw;
      width: 21vw;
      z-index: 3;

      #typewritter {
        display: flex;
        height: 30px;
        justify-content: flex-start;
        font-family: "Source Black";
        position: relative;
        text-transform: uppercase;
        
        p.element {
          color: white;  
        }
      }


      #marker {
        img {
          height: auto;
          width: 16.345708vw;
        }
      }
    }
  }
}

</style>
