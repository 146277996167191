import $ from 'jquery';

var d = $(document);

d.ready(function(){
  var animatedEl = document.querySelectorAll('.has-animation'),
      entry = [],
      vh = window.innerHeight;

      animatedEl.forEach(elem => {
        var animatedElOffset = elem.offsetTop - vh / 2;

        entry.push({
          point: animatedElOffset,
          elem: elem
        })
      });
  
  d.on('scroll', function(){
    for(var i in entry) {
      if (d.scrollTop() > entry[i].point && entry[i].elem.classList.contains('hidden')) entry[i].elem.classList.remove('hidden');
    }
  });
});