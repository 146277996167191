<template>
  <div id="dimension" class="has-animation hidden">
    <h2>ADD ANOTHER DIMENSION</h2>
    <h3>WE CREATE REVOLUTIONARY VIRTUAL &amp; AUGMENTED REALITY SOLUTIONS FOR MARKETING, RETAIL, AND ECOMMERCE</h3>
    <div>
      <p>
        We help organizations create future proof strategies to leverage these rapidly evolving new technologies. FUGO has a creative<br>production team specialized in augmented reality campaigns for brands, agencies and media.
      </p>
      <p>
        We work on the strategy from the beginning to guarantee the best results. Our team is made up of experts in 3D, graphic and<br>multimedia design, audiovisual production, animation and AR. Our team is leading the next wave of the technological revolution.
      </p>
    </div>
  </div>
</template>

<script>
  import '../js/lazy-fade.js';
</script>

<style lang="scss">
  #dimension {
    text-align: center;
    margin: auto;
    padding-bottom: 4vw;
    width: 80%;

    h3 {
      color: #505151;
      font-family: "Source Extra Light";
      font-size: 1.4vw;
      letter-spacing: 0.3em;
      line-height: 150%;
      margin-top: 4vw;
      margin-bottom: 1.5vw;
    }

    p {
      font-family: "Source Light";
      letter-spacing: 0.26em;
      line-height: 150%;
      font-size: 12px;
    }

    &.has-animation {
      transform: perspective(800px);
      transform-style: preserve-3d;

      h2, h3, > div { transition: all .8s ease }
      

      &.hidden {
        h2 {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 400px) rotateY(-40deg);
          transform: translate3d(0, 0, 400px) rotateY(-40deg);
        }

        h3 {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 400px) rotateY(40deg);
          transform: translate3d(0, 0, 400px) rotateY(40deg);
        }

        > div {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 400px) rotateY(-40deg);
          transform: translate3d(0, 0, 400px) rotateY(-40deg);
        }
      }
    }
  }
</style>