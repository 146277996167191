<template>
  <div id="contact" class="has-animation hidden">
    <h2>DISCOVER WHAT VR & AR CAN DO FOR YOU</h2>
    <div><button id="form_trigger">CONTACT US</button></div>
    <contact-form></contact-form>
  </div>
</template>
<script>
  import '../js/lazy-fade.js';
  import contactForm from './Form';

  export default {
    components: { contactForm },
    mounted: function() {
      this.$nextTick(function () {
        window.onload = () => {
          var formWrapper = document.querySelector('#form-wrapper');
  
          var formTrigger = document.querySelector('#form_trigger');
              formTrigger.addEventListener('click', () => {
                formWrapper.classList.remove('disappear', 'animated', 'fadeOut');
                formWrapper.classList.add('appear', 'animated', 'fadeIn');
              });
  
          var closeForm = document.querySelector('.close-form');
              closeForm.addEventListener('click', () => {
                formWrapper.classList.remove('appear', 'animated', 'fadeIn');
                formWrapper.classList.add('disappear', 'animated', 'fadeOut');
              });
        }
      })
    }
  }
</script>
<style lang="scss">
  #contact {
    width: 80%;
    margin: auto;
    height: auto;
    padding-bottom: 4.6vw;
    
    h2 {
      height: auto;
      line-height: 120%;
      margin-bottom: 3.6vw;
    }

    button {
      align-items: center;
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      background-image: url("../img/Textura.png");
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      border-radius: 5vw;
      color: white;
      display: flex;
      font-size: 1.5em;
      font-family: "Source Sans";
      height: 5.452436194895592vw;
      justify-content: center;
      letter-spacing: 0.1em;
      line-height: 6vw;
      margin: auto;
      width: 20.51044083526682vw;

      &:hover {
        cursor: pointer;
      }
    }

    &.has-animation {
      transition: all 1s ease;

      &.hidden {
        opacity: 0;
      }
    }
  }
</style>

