<template>
  <div id="fugo-scene">
    <a-scene arjs="debugUIEnabled: false">
        <a-marker 
          emitevents="true"
          preset="custom" 
          type="pattern" 
          :url="marker">

          <a-sound 
            :src="sound" 
            autoplay="false" 
            loop="true" 
            position="0 2 5"
            volume="2">
            </a-sound>
          
          <a-entity
            position="0 -2 0"
            rotation="90 180 0"
            scale="2.5 1.5 -0.10"
            :obj-model="obj">
          </a-entity>

        </a-marker>
      </a-scene>
      <a-entity camera look-controls></a-entity>
      <button id="play-sound" class="pause">Tap to play the music</button>
  </div>
</template>

<script>
  /* AR ASSETS */
  const markerAsset = require('../marker/marker.patt');
  const objAsset = require('../obj/astronaut.obj');
  const objMaterial = require('../obj/astronaut.mtl');
  const soundUrl = require('../audio/After_All.mp3');


  export default {
    name: 'fugo-scene',
    data() {
      return {
        marker: markerAsset,
        obj: `obj: url(${objAsset}); mtl: url(${objMaterial})`,
        sound: soundUrl
      }
    },
    mounted: function() {
      window.addEventListener('load', function(){
        /* DOM */
        var soundElem = document.querySelector('[sound]');
        var playSound = document.querySelector('#play-sound');
            playSound.addEventListener('click', function(){
              if (playSound.classList.contains('pause')) {
                soundElem.components.sound.playSound();
                playSound.classList.replace('pause', 'play');
              } 
              else if (playSound.classList.contains('play')) {
                soundElem.components.sound.pauseSound();
                playSound.classList.replace('play', 'pause');
              }
            });
      });
    }
  }
</script>
<style lang="scss" scoped>
  @import '../scss/_fonts.scss';

  button { 
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    bottom: 15px;
    color: white;
    display: flex;
    font-family: "Source Semi Bold";
    height: 50px;
    justify-content: center;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: fixed;
    right: 0;
    text-indent: -9000px;
    width: 100px;

    &.pause {
      background-image: url("../img/music-off.svg");
    }

    &.play {
      background-image: url("../img/music-on.svg");
    }
  }
</style>
