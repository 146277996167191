<template>
  <!-- Mobile -->
  <div v-if="mobile">
    <fugo-scene></fugo-scene>
  </div>

  <!-- Desktop -->
  <div id="container" v-else>
    <header-banner></header-banner>
    <experience></experience>
    <dimension></dimension>
    <use-cases></use-cases>
    <contact></contact>
    <footer-area></footer-area>
  </div>

</template>
<script>
  /* MOBILE DETECTION */
  import './js/mobile-detect.js';

  /* COMPONENTS */
  import fugoScene from './components/Scene';
  import headerBanner from './components/Header';
  import experience from './components/Experience';
  import dimension from './components/Dimension';
  import useCases from './components/Cases';
  import contact from './components/Contact';
  import footerArea from './components/Footer';

  export default {
    components: { fugoScene, headerBanner, experience, dimension, useCases, contact, footerArea },
    data() {
      return {
        mobile: mobileDetect()
      }
    }
  }
</script>