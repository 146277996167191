<template>
  <div id="use_cases" class="has-animation hidden">
    <div>
      <h2>TOP AR / VR USE CASES</h2>
      <div class="item_grid">
        <div>
          <div></div>
          <span>Interior modelling<br>in private homes<br>and office buldings.</span>
        </div>
        <div>
          <div></div>
          <span>Special modelling<br>for retail industry.</span>
        </div>
        <div>
          <div></div>
          <span>Virtual tutorials<br>and instructions<br>for all industries.</span>
        </div>
        <div>
          <div></div>
          <span>Awsome games<br>and entertainment.</span>
        </div>
        <div>
          <div></div>
          <span>Step by step for<br>cosmetics and<br>makeup.</span>
        </div>
        <div>
          <div></div>
          <span>Super attractive<br>designs to stand<br>out in the market.</span>
        </div>
        <div>
          <div></div>
          <span>Entertainment in<br>waiting rooms for<br>children.</span>
        </div>
        <div>
          <div></div>
          <span>Easy follow musical<br>instruments tutorials.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import '../js/lazy-fade.js';
</script>
<style lang="scss">
  #use_cases {
    background-color: black;
    padding-bottom: 3.3vw;

    > div {
      margin: auto;
      width: 80%;
    }

    h2 {
      color: white;
      margin-bottom: 3.3vw;
    }

    .item_grid {
      align-items: end;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;
      justify-items: center;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        margin-top: 20px;

        div {
          background-repeat: no-repeat;
          margin: 0 auto;
        }

        span {
          color: white;
          letter-spacing: 0.2em;
          display: block;
          font-size: 13px;
          line-height: 125%;
          height: 80px;
          padding-top: 18px;
        }

        &:first-child {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: 0 0;
            height: 81px;
            width: 48px;
          }
        }

        &:nth-child(2) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -49px 0;
            height: 44px;
            width: 70px;
          }
        }

        &:nth-child(3) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -120px 0;
            height: 37px;
            width: 77px;
          }
        }

        &:nth-child(4) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -198px 0;
            height: 60px;
            width: 60px;
          }
        }

        &:nth-child(5) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: 0 100%;
            height: 71px;
            width: 44px;
          }
        }

        &:nth-child(6) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -44px 100%;
            height: 34px;
            width: 62px;
          }
        }

        &:nth-child(7) {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -107px 100%;
            height: 59px;
            width: 57px;
          }
        }

        &:last-child {
          div {
            background-image: url("../img/cases_icons.svg");
            background-repeat: no-repeat;
            background-position: -165px 100%;
            height: 72px;
            width: 70px;
          }
        }
      }
    }

    &.has-animation {
      transform: perspective(800px);
      transform-style: preserve-3d;

      h2, .item_grid { transition: all .8s ease }
      

      &.hidden {
        h2 {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 400px) rotateY(-40deg);
          transform: translate3d(0, 0, 400px) rotateY(-40deg);
        }

        .item_grid {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 400px) rotateY(40deg);
          transform: translate3d(0, 0, 400px) rotateY(40deg);
        }
      }
    }
  }
</style>