<template>
  <div id="form-wrapper" class="">
    <form class="contact-form" @submit.prevent :action="action" method="post">
      <span class="close-form">X</span>
      <div>
        <div>
          <div>
            <label for="name">Full name*</label><span v-if="errors[0]">{{ errMsg[0] }}</span>
            <input v-model="name" type="text" name="name" id="name" placeholder="Enter your name">
          </div>
          <div>
            <label for="email">E-mail*</label><span v-if="errors[1]">{{ errMsg[1] }}</span>
            <input v-model="email" type="text" name="email" id="email" placeholder="Enter a valid email">
          </div>
          <div>
            <label for="telephone">Telephone</label><span v-if="errors[2]">{{ errMsg[2] }}</span>
            <input v-model="telephone" type="tel" name="telephone" id="telephone" placeholder="Phone number">
          </div>
        </div>
        <div>
          <div>
            <label for="comment">Comments*</label><span v-if="errors[3]">{{ errMsg[3] }}</span>
            <textarea v-model="comment" name="comment" id="comment" maxlength="5000" placeholder="Drop us a line"></textarea>
          </div>
        </div>
        <div>
          <input type="submit" value="SEND :)" @click="checkForm">
          <div class="sent">
            <div class="success">Thank you!</div>
            <div class="error">Oops! We ran into a problem</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  // jQuery
  import $ from 'jquery';
  
  // Send form
  const formAction = require('../send.php');

  export default {
    name: 'contactForm',

    /* FORM INITIAL VALUES */
    data() {
      return {
        name: null,
        email: null,
        telephone: null, // not required
        comment: null,
        errors: [false, false, false, false],
        errMsg: [null, null, null, null],
        action: formAction
      }
    },

    /* FORM VALIDATION */
    methods: {
      checkForm: function(e) {
        this.errors = [false, false, false, false];

        // Full name
        if(!this.name) {
          this.errors[0] = true;
          this.errMsg[0] = 'What\'s your name?';
        }

        // Email
        var validEmail = false;
        if(!this.email){
          this.errors[1] = true;
          this.errMsg[1] = 'We\'d like to write you back';
        }
        else if(this.email && !this.validEmail(this.email)){
          this.errors[1] = true;
          this.errMsg[1] = 'Please, enter a valid email';
        }
        else {
          validEmail = true;
        }

        // Telephone number
        if(isNaN(this.telephone)) {
          this.errors[2] = true;
          this.errMsg[2] = 'Only numbers, please';
        }

        // Comment
        if(!this.comment){ 
          this.errors[3] = true;
          this.errMsg[3] = 'We\'d love to hear about your project';
        }

        // Verify all the previous
        if(this.name && validEmail && this.comment){
          this.send();
          this.name = null;
          this.email = null;
          this.telephone = null;
          this.comment = null;
        }
      },

      /* EMAIL VALIDATION */
      validEmail: function(email) {
        var re = /\w+@\w+\.+[a-z]/;
        return re.test(email);
      },

      /* SEND FORM */
      send: function(name, email, telephone, comment) {
        $.ajax({
          type: 'post',
          url: this.action,
          data: $('.contact-form').serialize(),
          success: function(response) {
            $('.sent > .success').show();
            $('.sent > .error').hide();
            $('.sent').fadeIn().delay(3000).fadeOut();
          },
          error: function(response) {
            $('.sent > .success').hide();
            $('.sent > .error').show();
            $('.sent').fadeIn().delay(3000).fadeOut();
          }
        });

        return false;
      }
    }
  }
</script>
<style lang="scss">
  #form-wrapper {
    align-content: center;
    background-color: rgba(black, .5);
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: opacity 1s 1s linear;
    width: 100%;
    z-index: 3;

    &.appear {
      display: flex;
      opacity: 1;
    }

    &.disappear {
      opacity: 0;
    }
  }

  form {
    background-color: white;
    border-radius: 1vw;
    bottom: 0;
    box-shadow: 0 0 5px rgba(black, .5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    margin: auto;
    padding: 3vw;
    position: relative;
    width: 80%;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;

        label { 
          display: block;
          font-size: 2vw;
          margin-bottom: .5vw;
          text-transform: uppercase;
        }

        span {
          color: crimson;
        }

        &:first-of-type {
          padding-right: 3vw;

          div {
            margin-bottom: 1vw;
            width: 100%;

            input {
              height: 3vw;
              padding: 0 10px;
              width: 100%;

              margin-bottom: 1vw;
            }
          }
        }

        &:nth-of-type(2) {
          div {
            height: 100%;
          }
          
          textarea {
            display: block;
            height: 81%;
            padding: 10px;
            resize: none;
            width: 100%;
          }
        }

        &:nth-of-type(3) {
          width: 100%;

          input[type="submit"] {
            align-self: flex-end;
            -webkit-appearance: none;
            appearance: none;
            align-items: center;
            background-color: transparent;
            background-image: url("../img/Textura.png");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5vw;
            border: none;
            color: white;
            display: flex;
            font-size: 1.2em;
            height: 5.475638051044084vw;
            justify-content: center;
            letter-spacing: 0.1em;
            margin-right: -3px;
            margin-top: 1vw;
            padding: 0;
            width: 20.55684454756381vw;

            &:hover{
              cursor: pointer;
            }
          }
        }
      }
    }

    .close-form {
      align-items: center;
      border-radius: 50%;
      color: black;
      display: flex;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1vw;
      justify-content: center;
      position: absolute;
      right: 1%;
      text-align: center;
      top: 2%;
      transition: all .3s ease-in-out;
      width: 1.5vw;
      height: 1.5vw;

      &:hover {
        background-color: black;
        color: white;
        cursor: pointer;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: "Source Sans"
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-family: "Source Sans"
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-family: "Source Sans"
    }
    :-moz-placeholder { /* Firefox 18- */
      font-family: "Source Sans"
    }

    .sent {
      display: none;

      .success {
        color: green;
      }

      .error {
        color: crimson;
      }
    }
  }
</style>

