'use strict';

// Styles
import './scss/styles.scss';

// Animate.css
import 'animate.css';

// Vue
import Vue from 'vue';

import App from './App.vue';
new Vue({
  el: '#app',
  render: h => h(App)
});