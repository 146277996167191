<template>
  <div id="experience">
    <div id="ar-business" class="animated infinite pulse slow">
      <div>
        <span class="title">_coderio</span>
        <span class="subtext">AR BUSINESS<br>CARDS</span>
      </div>
    </div>
    <div id="fu-portal" class="animated infinite pulse slower">
      <div>
        <span class="title">FUGO</span>
        <span class="subtext">OFFICE<br>FANTASY PORTAL</span>
      </div>
    </div>
    <div class="animated infinite pulse slow">
      <div>
        <span class="title">MOVIE</span>
        <span class="subtext">SUPERNATURAL<br>EXPERIENCE</span>
      </div>
    </div>
    <div class="animated infinite pulse slower"><!-- circle --></div>
    <div class="animated infinite pulse slow"><!-- circle --></div>
    <div id="background"><!-- Parallax background --></div>
    <div id="ar-business-vid" class="video-wrapper">
      <div>
        <span class="close-vid">X</span>
        <iframe src="https://player.vimeo.com/video/296730780" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
    </div>
    <div id="fu-portal-vid" class="video-wrapper">
      <div>
        <span class="close-vid">X</span>
        <iframe src="https://player.vimeo.com/video/300342429" width="360" height="640" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';

  export default {
    mounted: function() {
      $('#ar-business').click(function() {
        $('#ar-business-vid').addClass('animated fadeInDown');
      });

      $('#fu-portal').click(function() {
        $('#fu-portal-vid').addClass('animated fadeInDown');
      });

      $('.close-vid').click(function(){
        $(this).parent().parent().removeClass('animated fadeInDown');
      });

      $('.video-wrapper').click(function(e){
        if (e.target != $(this).find('iframe')) {
          $(this).removeClass('animated fadeInDown');
        }
      });

      $(document).mousemove(function(e){
        $("#background").css({
          left: e.pageX * 0.03930523, 
          top: e.pageY * 0.03930523
        });
      })
    }
  }
</script>

<style lang="scss">
  #experience {
    height: 66.54292343387471vw;
    overflow: hidden;
    position: relative;
    width: 100%;

    > div {
      align-items: center;
      border: 1px solid white;
      border-radius: 50%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      text-align: center;
      z-index: 2;


      > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
      
      }

      &:first-of-type {
        height: 25.1508120649652vw;
        left: 16.24129930394432vw;
        top: 4.988399071925754vw;
        width: 25.1508120649652vw;

        &:hover {
          cursor: pointer;
        }

        > div {
          align-items: flex-end;

          span {
            &.title {
              letter-spacing: 0.25em;
            }

            &.subtext {
              font-size: 1.65vw;
              margin-right: .3vw;
            }
          }
        }
      }

      &:nth-of-type(2) {
        height: 21.20649651972158vw;
        right: 6.960556844547564vw;
        top: 16.33410672853828vw;
        width: 21.20649651972158vw;

        &:hover {
          cursor: pointer;
        }
      }

      &:nth-of-type(3) {
        height: 23.89791183294664vw;
        right: 29.83758700696056vw;
        top: 39.76798143851508vw;
        width: 23.89791183294664vw;

        &:hover {
          cursor: pointer;
        }
      }

      &:nth-of-type(4) {
        border: 1px solid white;
        bottom: 8.097447795823666vw;
        height: 8.700696055684455vw;
        left: 2.900232018561485vw;
        width: 8.700696055684455vw;
      }

      &:nth-of-type(5) {
        border: 1px solid white;
        height: 5.614849187935035vw;
        right: 24.12993039443155vw;
        top: 7.958236658932715vw;
        width: 5.614849187935035vw;
      }

      &:nth-of-type(6) {
        background-image: url("../img/260704-P4K1T2-273-min.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: unset;
        filter: brightness(0.9);
        height: 100%;
        left: 0;
        top: 0;
        transition: all .3s linear;
        transform: scale(1.1);
        width: 100%;
        z-index: 1;
      }

      &.video-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(black, .5);
        border-radius: unset;
        border: none;

        &.animated {
          display: flex;
        }

        > div {
          position: relative;

          .close-vid {
            display: block;
            border: 1px solid transparent;
            border-radius: 50%;
            position: absolute;
            line-height: 20px;
            top: -30px;
            right: -30px;
            height: 22px;
            width: 22px;
            text-align: center;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
            font-weight: bold;
            font-size: 1vw;
            transition: all .3s ease-in-out;

            &:hover {
              cursor: pointer;
              background-color: white;
              color: black;
              border-color: white;
            }
          }
        }
      }

      span {
        &.title {
          font-family: "Source Semi Bold";
          font-size: 3vw;
          letter-spacing: 0.2em;
          line-height: 75%;
          margin-bottom: 2.366589327146172vw;
        }

        &.subtext {
          font-family: "Source Light";
          font-size: 1.230101302460203vw;
          line-height: 1.5em;
          letter-spacing: 0.3em;
        }
      }
    }
  }
</style>
